<template>
  <v-app-bar app class="primary" dark>
    <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer" />
    <v-toolbar-title class="white--text text-uppercase">{{
      $t("welcome")
    }}</v-toolbar-title>
    <v-spacer></v-spacer>

    <v-btn-toggle tile group v-if="!this.$store.getters.isAuthenticated">
      <v-btn to="/login">Login</v-btn>
      <v-btn to="/register">Register</v-btn>
    </v-btn-toggle>
     <v-btn v-else :to="{ name: 'school', params: { id: 1 }}">Dashboard</v-btn>

    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark v-on="on">
            <v-icon>mdi-translate</v-icon>
            {{ $i18n.locale }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :value="item.title"
            @click="changeLocale(item.local)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  props: ["title", "connected"],
  data: () => ({
    langs: ["en", "fr"],
    items: [
      { title: "English", local: "en" },
      { title: "Français", local: "fr" }
    ]
  }),
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    }
  }
};
</script>
