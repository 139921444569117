<template>
  <div class="home">
    <AppBar />
    <v-content>
      <v-container fluid>
        <h1>Account</h1>

        <v-card>{{user}}</v-card>
        <v-card>{{isAuthenticated}}</v-card>
      </v-container>
    </v-content>
  </div>
</template>

<script>
// @ is an alias to /src
import AppBar from "@/components/AppBar.vue";

import { mapGetters } from "vuex";
export default {
  name: "Account",
  components: {
    AppBar
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      isAuthenticated: "isAuthenticated"
    })
  }
};
</script>
